import tokens from '@veneer/tokens'
import styled from 'styled-components'
import { smartQueries } from '../../../utils/device/device'

export const StyledContainer = styled.div`
  font-family: ${tokens.typography.family0};
  margin-bottom: 30px;

  .vn-animated-height__wrapper-inner > div {
    margin: 0;
    padding: 0 !important;
    border-color: #dbdbdb;
  }

  #orderAccordion > div {
    box-shadow: none;
  }

  [aria-expanded] {
    align-items: flex-start;

    svg {
      margin-top: ${tokens.layout.size3};
      margin-right: ${tokens.layout.size6};
      fill: #0278ab;
    }
    .order-total {
      position: absolute;
      right: ${tokens.layout.size4};
      top: 61px;
      text-align: right;
      font-size: ${tokens.typography.size0};
      color: ${tokens.color.gray9};
      letter-spacing: 0.5px;

      ${smartQueries.mobileXS(`
        top: 112px;
      `)}

      .total {
        color: ${tokens.color.gray9};
        font-size: ${tokens.typography.size4};
        line-height: ${tokens.typography.lineHeight4};
        display: block;
        margin-bottom: -4px;
      }

      .total + span {
        line-height: ${tokens.typography.lineHeight1};
        letter-spacing: 0;
      }
    }
  }
`

export const StyledPaymentDeliveryDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-left: ${tokens.layout.size3};
  margin: 4px 0 12px;
  border-top: solid 1px #dbdbdb;
`

export const StyledOrderDetailContainer = styled.div`
  font-family: ${tokens.typography.family0};

  .heading-2 {
    font-family: ${tokens.typography.family0};
    color: ${tokens.color.gray12};
    font-size: ${tokens.typography.size3};
    line-height: ${tokens.typography.lineHeight3};
    font-weight: 400;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`
