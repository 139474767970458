import tokens from '@veneer/tokens'
import styled from 'styled-components'
import { smartQueries } from '../../utils/device/device'

export const Container = styled.div`
  font-family: ${tokens.typography.family0};
  line-height: ${tokens.typography.lineHeight3};
  color: ${tokens.color.gray12};
  padding: 40px;
  ${smartQueries.mobileXS(`
    padding: ${tokens.layout.size4};
  `)}
`
export const Heading = styled.h1`
  font-family: ${tokens.typography.family0};
  font-size: ${tokens.typography.size7};
  font-weight: 400;
  color: ${tokens.color.gray12};
  line-height: ${tokens.typography.lineHeight7};
  margin-bottom: 8px;
`
