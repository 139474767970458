import tokens from '@veneer/tokens'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  font-family: ${tokens.typography.family0};

  .vn-animated-height__wrapper-inner > div {
    margin: 0;
    padding: 16px;
  }
`
export const StyledErrorMessage = styled.div`
  color: ${tokens.color.gray12};
  font-size: ${tokens.typography.size1};
  line-height: ${tokens.typography.lineHeight2};

  > div {
    margin-top: 10px;
  }
`

export const SubHeading = styled.div`
  color: ${tokens.color.gray12};
  letter-spacing: 0;
  font-size: ${tokens.typography.size2};
  font-weight: 400;
  line-height: ${tokens.typography.lineHeight2};
  margin-bottom: 40px;
  > span > a {
    color: #0278ab;
    line-height: ${tokens.typography.lineHeight3};
    &:hover {
      text-decoration: none;
    }
    &:focus,
    &:active {
      border: 1px solid #0278ab;
    }
  }
`
