import tokens from '@veneer/tokens'
import styled from 'styled-components'

export const StyledDeliveryDetails = styled.div`
  color: ${tokens.color.gray9};
  font-size: ${tokens.typography.size1};
  font-family: ${tokens.typography.family0};
  line-height: ${tokens.typography.lineHeight2};
  margin-top: 24px;

  img {
    margin-bottom: 8px;
  }

  p {
    margin: 0 0 2px;
  }
`
