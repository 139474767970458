import { useEffect, useState } from 'react'
import useSubscriptionClient from '../useSubscriptionClient'
import { Fulfillment, FulfillmentStatus } from '@/types/OrderList'
export const useGetFulfillment = () => {
  const subscriptionClient = useSubscriptionClient()

  const [fulfillmentResponse, setFulfillmentResponse] =
    useState<Fulfillment[]>()

  const [isFulfillmentLoading, setIsFulfillmentLoading] =
    useState<boolean>(true)

  const [isOrderFulfillmentError, setIsOrderFulfillmentError] =
    useState<boolean>(false)

  useEffect(() => {
    const getFulfillmentInfo = async () => {
      try {
        const responseData = await subscriptionClient.getFulfillment()
        setFulfillmentResponse(responseData)
        setIsOrderFulfillmentError(false)
      } catch (err) {
        setIsOrderFulfillmentError(true)
      } finally {
        setIsFulfillmentLoading(false)
      }
    }
    getFulfillmentInfo()
  }, [])
  const orderFulfillmentData: FulfillmentStatus = {
    loading: isFulfillmentLoading,
    fulFillments: fulfillmentResponse,
    error: isOrderFulfillmentError
  }
  return {
    data: orderFulfillmentData
  }
}
