import tokens from '@veneer/tokens'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: #f8fafb;
  font-family: ${tokens.typography.family0};
  font-style: normal;
  font-weight: 400;
  font-size: ${tokens.typography.size3};
  line-height: ${tokens.typography.lineHeight3};
  width: 100%;
`
