import tokens from '@veneer/tokens'
import styled from 'styled-components'

export const StyledPaymentDetail = styled.div`
  color: ${tokens.color.gray12};
  font-size: ${tokens.typography.size1};
  line-height: ${tokens.typography.lineHeight1};
  margin-top: ${tokens.layout.size2};
  img {
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 4px;
  }
`
